import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import axios from 'axios';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <DesignedTableCellS>Email</DesignedTableCellS>
        <DesignedTableCellS>Date</DesignedTableCellS>
      </TableRow>
    </TableHead>
  );
}

const DesignedTableCell = styled(TableCell)`
    font-size:15px;
    text-align:justify;
    margin-left:100px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;
const DesignedTableCellS = styled(TableCell)`
    font-size:18px;
    font-weight:semi-bold;
    text-align:justify;
    margin-left:100px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;
const Label = styled(Typography)`
    display: flex;
    text-align: justify;
    font-size: 25px;
    font-weight: bold;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    margin: 8px;
    padding: 8px;
`;


export default function DisplayNL() {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    axios.get('https://server.uniteenergycorp.com/user/newsletterfetch')
      .then(response => {
        setRows(response.data.reverse());  // Reverse the order of rows
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const visibleRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
    <Label>EMAIL LIST</Label>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.email}
                    sx={{ cursor: 'pointer' }}
                  >
                    <DesignedTableCell
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.email}
                    </DesignedTableCell>
                    <DesignedTableCell>{row.date}</DesignedTableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
