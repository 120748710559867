import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { styled} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const Title = styled(Typography)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 1000;
  text-transform: uppercase;
`;

const Content = styled(Typography)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 500;
  margin-top: 15px;
`;

const DesignedButton = styled(Button)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 315,
  height: 315,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  backgroundColor: '#E0FFFF',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 315,
    height: 'auto',
  },
  [theme.breakpoints.between('md', 'lg')]:{
    maxWidth:315,
    height:400
  }
}));

const Label = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 40,
  fontWeight: 'bold',
  textUnderlineOffset: 2,
  fontFamily: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  },
}));

const StyledCardActions = styled(CardActions)`
  margin-top: auto;
`;

const StyledButton = styled(Button)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 18px;
`;

export default function Posts() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://server.uniteenergycorp.com/user/postfetch')
      .then(response => {
        setRows(response.data.reverse());
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  const getFirst20Words = (text) => {
    return text.split(' ').slice(0, 20).join(' ') + '...';
  };

  const getFirst5Words = (text) => {
    return text.split(' ').slice(0, 5).join(' ') + '...';
  };

  const handleLearnMoreClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div style={{ padding: '30px' }}>
      <Label>Blogs</Label>
      <Grid container spacing={3} justifyContent="center" sx={{paddingTop:2}}>
        {rows.slice(0, 9).map((row) => (
          <Grid item xs={12} sm={6} md={4} key={row.id}>
            <StyledCard>
              <CardContent>
                <Title gutterBottom variant="h5" component="div">
                  {getFirst5Words(row.title)}
                </Title>
                <Content variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: getFirst20Words(row.content) }}>
                </Content>
              </CardContent>
              <StyledCardActions>
                <DesignedButton size="small" onClick={() => handleLearnMoreClick(row.title)}>
                  Learn More
                </DesignedButton>
                {row.pdf_file_path && (
                  <DesignedButton
                    size="small"
                    href={`https://server.uniteenergycorp.com/${row.pdf_file_path.replace(/\\/g, '/')}`} // Fix path format
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View PDF
                  </DesignedButton>
                )}
              </StyledCardActions>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <StyledButton variant="contained">
            <Link to="/blogs">
              More <ArrowForwardIcon />
            </Link>
          </StyledButton>
        </Grid>
      </Grid>
    </div>
  );
}
