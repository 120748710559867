import { useState } from 'react';
import { Box , TextField , Button , styled, Typography  } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {API} from '../service/api.js';
import { useNavigate } from 'react-router-dom';

const Component = styled(Box)`
    component:main;
    width:400px;
    margin:auto;
    box-shadow:5px 2px 5px 2px rgb(0 0 0/0.6);
    maxWidth:xs;
    margin-top: 85px;
`;

const Label = styled(Typography)`
    text-align: center;
    font-size:25px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Lock = styled(LockIcon)`
    color:#286790;
    margin:3px;
`;

const Wrapper = styled(Box)`
    padding:25px 35px;
    display:flex;
    flex:1;
    flex-direction:column;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    & > div{
        margin-top:20px;
    }
`;

const TitleWrapper = styled(Box)`
    margin-top:10px;
    place-content: center;
    display:flex;
    flex:1;
    flex-direction:row;
`;

const LoginButton = styled(Button)`
    text-transform:none;
    background:#FB641B;
    color:#FFF;
    height:48px;
    border-radius:2px;
    margin-top:25px;
    font-size:20px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;
const loginInitialValues = {
    username: '',
    password: ''
}

const Login = ({isUserAuthenticated}) => {
    const navigate = useNavigate();
    const [error , setError] = useState('');
    const [login , setLogin] = useState(loginInitialValues);
    const onValueChange = (e) => {
        setLogin({...login , [e.target.name]:e.target.value })
    }
    const loginUser = async () => {
        let response = await API.userLogin(login);
        if (response.isSucess){
            setError(' ');
            sessionStorage.setItem('accessToken',`Bearer ${response.data.accessToken}`);
            sessionStorage.setItem('refreshToken',`Bearer ${response.data.refreshToken}`);

            isUserAuthenticated(true);
            navigate('/user');
        }else{
            setError('something went wrong');
        }
    }

    return (
        <Component>
            <Box>
                <img src="/img/logo/Logo Unite Energy.png" alt="login" className="w-[75px] m-auto flex pt-[50px] " />
                <TitleWrapper>
                    <Lock />
                    <Label component="h1" variant="h5" >Sign in </Label>
                </TitleWrapper>
                <Wrapper>
                    <TextField required value={login.username} onChange={(e) => onValueChange(e)} name="username" label="Enter Username" id="username"/>
                    <TextField required value={login.password} onChange={(e) => onValueChange(e)} name="password" label="Enter Password" type="password" id="password" />
                    <LoginButton sx={{mt:3 , mb:2}} variant="contained" onClick={() => loginUser()}>Login</LoginButton> 
                    {error && <Typography color="red" variant="body2" style={{ marginTop: '10px' }}>{error}</Typography>}
                </Wrapper>
            </Box>
        </Component>
    );
}

export default Login;