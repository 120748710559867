import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useState } from 'react';

// Components
import Login from './components/account/login';
import Home from './components/Home/home';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Boiler from './components/boilers/boiler';
import Resources from './components/resources/resources';
import AboutUs from './components/aboutUs/about';
import Abrasive from './components/abrasiveWheel/abrasive';
import Spares from './components/spare&services/spare';
import DashboardLayout from './components/header/dashboardLayout';
import CreatePost from './components/create-post/Create';
import DisplayContact from './components/contact/display';
import DisplayNL from './components/newsletter/displayN';
import AddUser from './components/account/addUser';
import ModifyPost from './components/create-post/Modify';
import BlogDetail from './components/Home/blog';
import PostsDisplay from './components/posts/posts';

const PrivateRoute = ({ isAuthenticated, children }) => {
  return isAuthenticated ? children : <Navigate replace to="/login" />;
};

function Layout({ isAuthenticated }) {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isUserPath = location.pathname.startsWith('/user');

  return (
    <>
      {!isLoginPage && !isUserPath && <Header />}
      <Outlet />
      {!isLoginPage && !isUserPath && <Footer />}
    </>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout isAuthenticated={isAuthenticated} />}>
          <Route path="/login" element={<Login isUserAuthenticated={setIsAuthenticated} />} />
          <Route path="/" element={<Home />} />
          <Route path="/boilers" element={<Boiler />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/abrasive-wheel" element={<Abrasive />} />
          <Route path="/spares-services" element={<Spares />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path='/blogs' element={<PostsDisplay />} />
          <Route
            path="/user/*"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <DashboardLayout setIsAuthenticated={setIsAuthenticated} />
              </PrivateRoute>
            }
          >
            <Route path="enquiry" element={<DisplayContact />} />
            <Route path="newsletter-subscription" element={<DisplayNL />} />
            <Route path="create-post" element={<CreatePost />} />
            <Route path="modify-post" element={<ModifyPost />} />
            <Route path="add-user" element={<AddUser />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
