import { Box , Button, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)(({theme}) => ({
    display:'flex',
    flexDirection: 'column',
    marginTop:15,
    [theme.breakpoints.down('sm')]:{
        marginLeft:30,
    },
    [theme.breakpoints.down('md')]:{
        marginLeft:30,
    },
}));

const StyledButton = styled(Button)`
    width:65%;
    margin: 5px 6px;
    margin-left:15px;
    background-color:#1976d2;
    color:white;
    font-size:14px;
    &:disabled{
        background-color:#1976d2;
        color:white;
    }
    border-radius:100px;
    padding:8px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Label = styled(Typography)`
     font-size: 22px;
     font-weight: bold;
     font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Commitments = () => {

    return(
        <Wrapper>
            <Label>Commitments</Label>
            <StyledButton variant="contained" disabled>Boiler Spare Part</StyledButton>
            <StyledButton variant="contained" disabled>Stroker Parts</StyledButton>
            <StyledButton variant="contained" disabled>Auxillary Spare</StyledButton>
            <StyledButton variant="contained" disabled>Experts Backup</StyledButton>
            <StyledButton variant="contained" disabled>Performance & Quality</StyledButton>
            <StyledButton variant="contained" disabled>Service & Support</StyledButton>
            <StyledButton variant="contained" disabled>Design & Retrofit</StyledButton>
            <StyledButton variant="contained" disabled>Erection Services</StyledButton>
            <StyledButton variant="contained" disabled>Commissioning Services</StyledButton>
            <StyledButton variant="contained" disabled>Consultancy</StyledButton>
        </Wrapper>
    )
}

export default Commitments;