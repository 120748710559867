import { Box, Typography, styled , Grid} from '@mui/material';
import { Helmet } from 'react-helmet';
//components
import Newsletter from '../newsletter/newsletter';
import Commitments from '../Home/commitments';

const Content = styled(Box)`
    margin-top: 25px;
`;

const ImageContainer = styled(Box)`
    padding-top: 64.7px;
`;

const Image = styled(Box)`
    background: url('/img/slideshow/Main photo6.jpg') center/55% repeat-x #000;
    background-size: cover;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


const SubHeading = styled(Typography)`
    font-size: 50px;
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 50px;
    font-weight:500;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Boiler = () => {
    return (
        <Box>
            <Helmet>
                <title>Boiler - Unite Energy Corporation LLP</title>
            </Helmet>
            <ImageContainer>
                <Image>
                    <SubHeading>BOILER</SubHeading>
                </Image>
            </ImageContainer>
            <Content>
                <Grid container spacing={2}>
                    <Grid items xs={12} md={9}>
                        <div>
                        <ul className="ml-[100px] list-disc text-[15.5px] font-serif font-medium mt-[15px] text-justify mr-[40px] tracking-widest">
                                <li>NEW BOILER & CONSULTANCY</li>
                                <li>PACKAGE BOILER</li>
                                <li>APH-AIR PRE HEATER TUBE, SLEEVE WORK</li>
                                <li>STOKER WORK</li>
                                <li>VESSELS, TANK, HOPPER, SILO WORK</li>
                                <li>CASING, DUCTING, PIPING WORK</li>
                                <li>DAMPER, GATE, MANHOLE</li>
                                <li>DRUM FEEDER, SCREW FEEDER WORK</li>
                                <li>EXPANSION BELLOW- METALLIC, NON METALLIC</li>
                                <li>STRUCTURE, RATING, HANDRAIL WORK</li>
                                <li>SITE ERECTION, REPAIR & RETROFIT</li>
                                <li>FABRICATION</li>
                                <li>BOILER MODIFICATION & CONVERSION</li>
                                <li>FAILURES, CORRECTIONS & ANALYSIS</li>
                                <li>AUTOMATION & TUNING</li>
                                <li>BOILER OPERATION & CONTROL</li>
                                <li>TECHNICAL CONSULTANCY- ON-LINE / OFF-LINE</li>
                                <li>EXPERTS VISIT & SUPERVISION</li>
                                <li>TRAINING & SKILL ENHANCEMENT</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid items xs={12} md={3}>
                        <Commitments />
                        <Newsletter />
                    </Grid>
                </Grid>
            </Content>
        </Box>

    );
}

export default Boiler;
