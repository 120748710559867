import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, styled } from '@mui/material';

import Newsletter from '../newsletter/newsletter';
import Commitments from '../Home/commitments';

const Wrapper = styled(Box)`
    padding-top: 100px;
`;

const Title = styled(Typography)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 1000;
  text-transform: uppercase;
`;

const Content = styled(Typography)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 500;
  margin-top: 15px;
`;

const DesignedButton = styled(Button)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 315,
  height: 315,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  backgroundColor: '#E0FFFF',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 315,
    height: 'auto',
  },
}));

const StyledCardActions = styled(CardActions)`
  margin-top: auto;
`;

export default function PostsDisplay() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://server.uniteenergycorp.com/user/postfetch')
      .then(response => {
        setRows(response.data.reverse()); // Reverse the order of rows
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  const getFirst20Words = (text) => {
    return text.split(' ').slice(0, 20).join(' ') + '...';
  };

  const getFirst5Words = (text) => {
    return text.split(' ').slice(0, 5).join(' ') + '...';
  };

  const handleLearnMoreClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <Wrapper>
        <Grid container spacing={2}>
            <Grid items xs={12} md={9}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25px', justifyContent: 'center', margin:'10px' }}>
                    {rows.map((row) => (
                        <StyledCard key={row.id}>
                        <CardContent>
                            <Title gutterBottom variant="h5" component="div">
                            {getFirst5Words(row.title)}
                            </Title>
                            <Content variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: getFirst20Words(row.content)}}>
                            </Content>
                        </CardContent>
                        <StyledCardActions>
                            <DesignedButton size="small" onClick={() => handleLearnMoreClick(row.title)}>
                            Learn More
                            </DesignedButton>
                            {row.pdf_file_path && (
                            <DesignedButton
                                size="small"
                                href={`https://server.uniteenergycorp.com/${row.pdf_file_path.replace(/\\/g, '/')}`} // Fix path format
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View PDF
                            </DesignedButton>
                            )}
                        </StyledCardActions>
                        </StyledCard>
                    ))}
                </div>
            </Grid>
            <Grid items xs={12} md={3} >
                <Commitments />
                <Newsletter />
            </Grid>
        </Grid>
    </Wrapper>
  );
}
