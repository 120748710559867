import { Box, CssBaseline, AppBar, Toolbar, IconButton, Typography, Button, Drawer, List } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate , Outlet } from 'react-router-dom';
import { styled } from '@mui/material';

const drawerWidth = 240;

const StyledList = styled(List)`
  display:flex;
  flex-direction:column;
  text-align:justify;
  margin:8px;
  padding:8px;
  font-size:18px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledLink = styled(Link)`
  margin-top:15px;
  padding-top:8px;

  &:hover{
    box-shadow: 20px 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

function DashboardLayout({ setIsAuthenticated }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    navigate('/login');
  };

  const drawer = (
    <div>
      <Toolbar />
      <StyledList>
        <StyledLink to='/user/enquiry'>Enquiry</StyledLink>
        <StyledLink to='/user/newsletter-subscription'>Newsletter Subscription</StyledLink>
        <StyledLink to='/user/create-post'>Create Post</StyledLink>
        <StyledLink to='/user/modify-post'>Modify Post</StyledLink>
        <StyledLink to='/user/add-user'>Add User</StyledLink>
      </StyledList>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }} className='font-serif'>
            Unite Energy Corporation LLP
          </Typography>
          <Button color="inherit" className='font-serif' onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default DashboardLayout;
