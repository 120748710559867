import { Container, Typography} from '@mui/material';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-2 mt-[15px]">
      <Container>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <Typography variant="body1" className="text-center md:text-left font-serif">
            &copy; {new Date().getFullYear()} Unite Energy Corporation LLP. All rights reserved.
          </Typography>
          <div className="mt-4 mr-4">
            <img src='/img/self photo.jpg' alt='Mr. Parmesh Jain' className=" w-[100px] h-auto"/>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;