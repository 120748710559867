import { Grid } from "@mui/material";
import { Helmet } from 'react-helmet';
//components
import Slideshow from "../slideshow/slideshow";
import Carasoul from "./owlcarasoul";
import Posts from "./post";
import Commitments from "./commitments";
import Contact from "../contact/contact";
import Newsletter from "../newsletter/newsletter";

const Home = () => {
    return (
        <div>
            <Helmet>
                <title>Unite Energy Corporation LLP</title>
                <meta name="description" content="Unite Energy Corporation LLP is keen to provide Spare Parts Supply &amp; Services,
                Equipment supply, Erection, Commissioning, Training, Root Cause analysis, Trouble
                shooting, Repair &amp; Retrofit, Grate Stoker, Air Pollution control equipments,Material
                handling, Ash Handling, Fans, Pumps and other Auxiliaries,Boiler water
                chemistry with support of our team &amp; business partners etc." />
                <meta name="keywords" content="Unite Energy Corporation LLP, unite energy, unite energy corporation, unite energy corporation llp, uecl, abrasive product, abrasive wheel, spares & services, Unite energy, UNITE ENERGY, UNITE, unite, Unite, scaph in boiler, boiler waterwall abrasion, slop fired boiler, furnace waterwall abrasion" />
                <meta name="author" content="Unite Energy Corporation LLP"></meta>
            </Helmet>
            <Slideshow />
            <div className="mt-[15px]">
                <Carasoul />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Posts />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Commitments />
                        <Newsletter />
                    </Grid>
                </Grid>
                <Contact />
            </div>
        </div>
    )
}

export default Home;
