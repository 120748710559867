import { Typography, TextField, IconButton, Box, styled } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { API } from "../service/api";
import { useState } from "react";

const LetterColumn = styled(Box)(({ theme }) =>({
    marginTop: 25,
    [theme.breakpoints.down('sm')]:{
        marginLeft:30
    },
    [theme.breakpoints.down('md')]:{
        marginLeft:30
    },
}));

const Label = styled(Typography)`
    font-size: 22px;
    font-weight: bold;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const ContainedIconButton = styled(IconButton)`
    background-color: #1976d2;
    color: white;
    &:hover {
        background-color: #1565c0;
    }
    border-radius: 4px;
    padding: 8px;
    margin-top: 1.5px;
`;

const StyledTextBar = styled(TextField)`
    margin-top: 2px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const emailInitialValue = {
    email: '',
    date: ''
}

const Newsletter = () => {
    const [error, setError] = useState('');
    const [entry, setEntry] = useState(emailInitialValue);
    const [success, setSuccess] = useState('');

    const onValueChange = (e) => {
        setEntry({ ...entry, [e.target.name]: e.target.value });
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const newsletterEmail = async () => {
        if (!validateEmail(entry.email)) {
            setError('Please enter a valid email address.');
            setSuccess('');
            return;
        }
        const currentDate = new Date().toISOString();
        try {
            let response = await API.newsletterEntry({...entry,date:currentDate});
            console.log('API Response:', response); // Log the response for debugging
            if (response.isSucess) { // Adjusted to check for isSucess
                setError('');
                setSuccess('Successfully subscribed to the newsletter!');
                setEntry(emailInitialValue); // Clear the email field on success
            } else {
                setError('Something went wrong. Please try again.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error:', error); // Log any error from the API call
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    }

    return (
        <LetterColumn>
            <Label>UECL Newsletter</Label>
            <StyledTextBar
                variant="outlined"
                placeholder="Your Email..."
                required
                size="small"
                value={entry.email}
                name="email"
                onChange={(e) => onValueChange(e)}
                error={!!error}
            />
            <ContainedIconButton variant="contained" color="primary" onClick={newsletterEmail}>
                <ArrowForwardIosIcon />
            </ContainedIconButton>
            {error && <Typography color="red" variant="body2" style={{ marginTop: '10px' }}>{error}</Typography>}
            {success && <Typography color="green" variant="body2" style={{ marginTop: '10px' }}>{success}</Typography>}
        </LetterColumn>
    );
}

export default Newsletter;
