import { Box, Typography, styled , Grid} from '@mui/material';
import { Helmet } from 'react-helmet';
//components
import Newsletter from '../newsletter/newsletter';
import Commitments from '../Home/commitments';

const Content = styled(Box)`
    margin-top: 25px;
`;

const ImageContainer = styled(Box)`
    padding-top: 64.7px;
`;

const Image = styled(Box)`
    background: url('/img/slideshow/Main photo6.jpg') center/55% repeat-x #000;
    background-size: cover;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


const SubHeading = styled(Typography)(({theme}) => ({
    fontSize: 50,
    background: '#ffffff',
    padding: 5,
    borderRadius: 50,
    fontWeight:500,
    fontFamily: "Times New Roman",
    [theme.breakpoints.down('sm')]:{
        fontSize:25
    },
}));

const Label = styled(Typography)`
    font-size:24px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight:1000;
`;

const Spares = () => {
    return (
        <Box>
            <Helmet>
                <title>Spares & Services - Unite Energy Corporation LLP</title>
            </Helmet>
            <ImageContainer>
                <Image>
                    <SubHeading>
                        SPARE AND SERVICES
                    </SubHeading>
                </Image>
            </ImageContainer>
            <Content>
                <Grid container spacing={2}>
                    <Grid items xs={12} md={9}>
                        <div>
                            <div className="flex flex-col text-center m-5">
                                <Label sx={{color:'#ff0000'}}>SPECIALISATION IN THERMAL POWER PLANTS</Label>
                                <Label sx={{color:'#5757bd'}}>(BIOMASS / SLOP- SPENT WASH / BAGASSE / COAL / OIL & GAS / WHR / HRSG)</Label>
                            </div>
                            <ul className="ml-[100px] list-disc text-[15.5px] font-serif font-medium mt-[15px] text-justify mr-[40px] tracking-widest">
                                <li>SPARE PARTS OF BOILER & AUXILIARIES</li>
                                <li>EQUIPMENT SUPPLY like FEEDER, CONVEYOR, BAG FILTER</li>
                                <li>ERECTION, FABRICATION</li>
                                <li>REPAIR & RETROFIT</li>
                                <li>MAINTENANCE & SHUTDOWN WORK</li>
                                <li>PIPING, DUCTING, TANK & CONTRACT WORK</li>
                                <li>APH, BOILER BANK, ECONOMISER REPLACEMENT</li>
                                <li>GRATE STOKER RETROFIT & SPARE SUPPLY</li>
                                <li>WET SCRUBBER, ESP, BAG FILTER RETROFIT & SPARE</li>
                                <li>ENGINEERING & CONSULTANCY SERVICES</li>
                                <li>TRAINING & SKILL ENHANCEMENT</li>
                                <li>INSPECTION & COMMISSIONING SERVICES</li>
                                <li>BOILER PERFORMANCE & ENERGY AUDIT</li>
                                <li>TROUBLE SHOOTING & FAILURE ANALYSIS</li>
                                <li>BOILER WATER CHEMISTRY ANALYSIS & SERVICES</li>
                                <li>INSTRUMENTATION AUTOMATION & UPGRADATION</li>
                                <li>NDT SERVICES - EDDY CURRENT & IRIS TESTING</li>
                                <li>TURBINE INSPECTION, MAINTENANCE, PERFORMANCE</li>
                                <li>QUALITY CONTROL & AUDIT CONSULTANCY like PROJECT SPECIFIC DOCUMENTATION, 
                                    WELDING QUALIFICATIONS WPS/PQR/WPQ, ISO TRAINING, IMPLEMENTATION, AUDITS, MATERIAL &
                                     STAGE-WISE INSPECTIONS, FINAL DOCUMENTATIONS/DOSSIER etc</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid items xs={12} md={3}>
                        <Commitments />
                        <Newsletter />
                    </Grid>
                </Grid>
            </Content>
        </Box>

    );
}

export default Spares;
