import { Box, Typography, styled, Grid, Container, ImageList, ImageListItem, Dialog, DialogContent, Card, CardMedia } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
//components
import Newsletter from '../newsletter/newsletter';
import Commitments from '../Home/commitments';

const Content = styled(Box)`
    margin-top: 25px;
`;

const ImageContainer = styled(Box)`
    padding-top: 64.7px;
`;

const Image = styled(Box)`
    background: url('${process.env.PUBLIC_URL}/img/slideshow/Main%20photo6.jpg') center/55% repeat-x #000;
    background-size: cover;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const SubHeading = styled(Typography)(({ theme }) => ({
    fontSize: 50,
    background: '#ffffff',
    padding: 5,
    borderRadius: 50,
    fontWeight: 500,
    fontFamily: "Times New Roman",
    [theme.breakpoints.down('sm')]: {
        fontSize: 25
    },
}));

const itemData = [
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%201.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%202.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%203.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%204.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%205.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%206.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%207.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%208.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%209.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2010.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2011.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2012.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2013.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2014.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2015.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2016.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2017.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2018.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2019.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2020.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2021.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2022.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2023.png`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2024.jpeg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2025.jpeg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2026.jpeg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2027.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2028.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2029.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2030.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/abrasive%2031.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/32.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/33.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/34.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/35.jpg`,
    `${process.env.PUBLIC_URL}/img/Abressive%20Wheel/36.jpg`
];


const Gallery = styled(ImageList)`
  width: 98%;
  margin: 16px auto; // Center the gallery and add some margin
  padding: 8px;
  display: grid; 
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); // Responsive columns
  grid-gap: 8px; 
`;

const ImageItem = styled(CardMedia)`
    image{
        display: block;
        width: 100%; 
    }
`;

const Abrasive = () => {
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const handleClickOpen = (image) => {
        setCurrentImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentImage('');
    };

    return (
        <Box>
            <Helmet>
                <title>Abrasive Wheel - Unite Energy Corporation LLP</title>
            </Helmet>
            <ImageContainer>
                <Image>
                    <SubHeading>ABRASIVE PRODUCT</SubHeading>
                </Image>
            </ImageContainer>
            <Content>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Container>
                            <Gallery cols={3} rowHeight={164} gap={8}>
                                {itemData.map((item, index) => (
                                    <ImageListItem key={index} onClick={() => handleClickOpen(item)}>
                                        <Card sx={{ cursor: 'pointer' }}>
                                            <ImageItem
                                                component="img"
                                                image={item}
                                                alt={`Abrasive ${index + 1}`}
                                            />
                                        </Card>
                                    </ImageListItem>
                                ))}
                            </Gallery>
                            <Dialog open={open} onClose={handleClose} maxWidth="md">
                                <DialogContent>
                                    <img src={currentImage} alt="Selected Abrasive" style={{ width: '100%' }} />
                                </DialogContent>
                            </Dialog>
                        </Container>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Commitments />
                        <Newsletter />
                    </Grid>
                </Grid>
            </Content>
        </Box>
    );
}

export default Abrasive;
