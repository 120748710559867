import React, { useState, useEffect } from "react";

const images = [
  '/img/slideshow/main photo 1.jpg',
  '/img/slideshow/main photo2.jpg',
  '/img/slideshow/main photo 3.jpg',
  '/img/slideshow/main photo 4.jpg',
  '/img/slideshow/main photo5.jpg',
  '/img/slideshow/Main photo6.jpg'
];

const Slideshow = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(prevIndex => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-[720px] top-[0.1] overflow-hidden">
      <img
        src={images[currentImage]}
        alt={`Slide ${currentImage + 1}`}
        className="w-full h-full object-cover"
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          src="/img/logo/uecl abrasive logo.png"
          alt="Unite Energy Coorporation LLP"
          className="w-48 h-auto"
        />
      </div>
    </div>
  );
};

export default Slideshow;
