import { Box, Typography, styled , Grid} from '@mui/material';
import {Helmet} from 'react-helmet';
//components
import Newsletter from '../newsletter/newsletter';
import Commitments from '../Home/commitments';

const Content = styled(Box)`
    margin-top: 25px;
`;

const ImageContainer = styled(Box)`
    padding-top: 64.7px;
`;

const Image = styled(Box)`
    background: url('/img/slideshow/Main photo6.jpg') center/55% repeat-x #000;
    background-size: cover;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


const SubHeading = styled(Typography)`
    font-size: 50px;
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 50px;
    font-weight:500;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Para = styled(Typography)`
    font-size:20px;
    color:#000000;
    padding:8px;
    text-align:justify;
    font-weight: 400;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Heading = styled(Typography)`
    margin:15px;
    text-align:center;
    font-size:25px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight:1000;
    color:#5757bd;
`;

const TagLine = styled(Typography)`
    margin:5px;
    font-size:27.5px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    font-weight:1000;
`;


const AboutUs = () => {
    return (
        <Box>
            <Helmet>
            <title>About-Us - Unite Energy Corporation LLP</title>
            <meta name="description" content="Unite Energy Corporation LLP is keen to provide Spare Parts Supply &amp; Services,
            Equipment supply, Erection, Commissioning, Training, Root Cause analysis, Trouble
            shooting, Repair &amp; Retrofit, Grate Stoker, Air Pollution control equipments,Material
            handling, Ash Handling, Fans, Pumps and other Auxiliaries,Boiler water
            chemistry with support of our team &amp; business partners etc."/>
            </Helmet>
            <ImageContainer>
                <Image>
                    <SubHeading>ABOUT US</SubHeading>
                </Image>
            </ImageContainer>
            <Content>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <div className="m-[15px]">
                            <Para>
                                Unite Energy Corporation LLP established with a vision of energy conservation, 
                                overall thermal power plant improvement leading to uninterrupted work environment 
                                & optimum utilization of all available resources in a plant efficiently. 
                                We are striving to achieve customer's satisfaction with our specialist team 
                                & quality work environment.
                            </Para>
                            <Heading>
                                ENERGY CONSERVATION & TROUBLE FREE WORK ENVIRONMENT
                            </Heading>
                            <Para>
                                Unite Energy Corporation LLP is keen to provide Spare Parts Supply & Services, 
                                Equipment supply, Erection, Commissioning, Training, Root Cause analysis, 
                                Trouble shooting, Repair & Retrofit, Grate Stoker, Air Pollution control 
                                equipments,Material handling, Ash Handling, Fans, Pumps and other Auxiliaries,
                                Boiler water chemistry with support of our team & business partners etc. 
                                Specially, Make ISGEC - I.J.T. Boiler, All auxiliaries & equipment spare 
                                parts supply and services available. In our expert team, ISGEC / IJT ex-employees are 
                                also associated. We are having experts team for Fabrication, Erection, Installation, 
                                APH repair & retrofit work, Steel Fabrication & Erection work,Piping work, 
                                Ducting & Casing work, Manufacturing & Fabrication of Equipments, Tanks, Hopper, 
                                Cone, Vessels, Screw Conveyors, RAV, Dampers, Drum feeders, SCAPH, Silencer, Structures etc 
                                from all types of steels like Carbons steel, Alloys steel, Stainless steel etc 
                                Unite Energy Corporation LLP is keen to provide our Spare Parts Supply & Services 
                                support to you to mitigate the irregularities in the plant, best technical services 
                                to mitigate breakdown, minimize downtime, improvise design and system performance 
                                through inspection, operational recommendations, genuine analysis, training and 
                                skill enhancement etc, to improve the overall plant's health and performance.
                            </Para>
                            <div className="flex flex-row place-content-center max-[400px]:flex-col">
                                <TagLine sx={{color: '#ff0000'}}>CONSIDERATION & EFFORTS</TagLine>
                                <TagLine >-</TagLine>
                                <TagLine sx={{color: '#5757bd'}}>A VISION TO BREAK OLD PRACTICES</TagLine>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Commitments />
                        <Newsletter />
                    </Grid>
                </Grid>
            </Content>
        </Box>

    );
}

export default AboutUs;
