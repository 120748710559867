import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled, Typography, Button, TextField, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloseIcon from '@mui/icons-material/Close';
import { API } from '../service/api';

const Title = styled(Typography)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 1000;
  text-transform: uppercase;
`;

const Content = styled(Typography)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 500;
  margin-top: 15px;
`;

const DesignedButton = styled(Button)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledCard = styled(Card)`
  max-width: 315px;
  flex: 1 0 30%;
  max-height: 315px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #E0FFFF;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const StyledCardActions = styled(CardActions)`
  margin-top: auto;
`;

const StyledTextField = styled(TextField)`
  margin: 8px 0;
  width: 100%;
  font-size: 18px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledButton = styled(Button)`
  margin: 8px;
  padding: 8px;
  width: 100px;
  font-size: 18px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const ModalBox = styled(Box)`
  position: relative;
  width: 100%;
  max-height: 80vh; /* Adjusted from 2000vh to 80vh */
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QuillWrapper = styled(Box)`
  margin: 8px 0;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export default function PostsDisplay() {
  const [rows, setRows] = useState([]);
  const [editEntry, setEditEntry] = useState({ title: '', content: '', pdf_file_path: '' });
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const [SaveError, setSaveError] = useState('');
  const [SaveSuccess, setSaveSuccess] = useState('');
  const [currentTitle , setCurrentTitle] = useState('');

  useEffect(() => {
    axios.get('https://server.uniteenergycorp.com/user/postfetch')
      .then(response => {
        setRows(response.data.reverse());
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  const handleEditChange = (e) => {
    setEditEntry({ ...editEntry, [e.target.name]: e.target.value });
  };

  const handleContentChange = (content) => {
    setEditEntry(prevState => ({ ...prevState, content }));
  };

  const handleOpen = (row) => {
    setEditEntry(row);
    setCurrentTitle(row.title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveChanges = async () => {
    try {
        let response = await API.updatePost({
            currentTitle: currentTitle,
            newTitle: editEntry.title,
            newContent: editEntry.content,
        });

        if(response.data.msg === 'Post updated successfully'){
            setSaveError('');
            setSaveSuccess('Post Updated Successfully');
            handleClose();
        } else {
            setSaveError(response.data.msg || 'Something went wrong while updating user. Please try again.');
            setSaveSuccess('');
        }
    } catch (error){
        setSaveError('Something went wrong while updating post. Please try again.');
        setSaveSuccess('');
    }
};

  const handleDeleteClick = (title) => {
    setDeletePost(title);
    setDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try{
        const response = await axios.delete(`https://server.uniteenergycorp.com/user/deletePost/${deletePost}`);
        if(response.data.msg === 'Post deleted successfully'){
            setRows(prevRows => prevRows.filter(row => row.title !== deletePost));
            setDeletePost(null);
            setDialogOpen(false);
        } else {
            console.error(response.data.msg || 'Something went wrong while deleting post.');
        }
    } catch (error) {
        console.error('Error while deleting post:', error);
    }
};

  const handleDeleteCancel = () => {
    setDeletePost(null);
    setDialogOpen(false);
  };

  const getFirst20Words = (text) => {
    return text.split(' ').slice(0, 20).join(' ') + '...';
  };

  const getFirst5Words = (text) => {
    return text.split(' ').slice(0, 5).join(' ') + '...';
  };

  return (
    <Box>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25px', justifyContent: 'center', margin: '10px' }}>
        {rows.map((row) => (
          <StyledCard key={row.id}>
            <CardContent>
              <Title gutterBottom variant="h5" component="div">
                {getFirst5Words(row.title)}
              </Title>
              <Content variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: getFirst20Words(row.content) }} />
            </CardContent>
            <StyledCardActions>
              <DesignedButton size="small" onClick={() => handleOpen(row)}>
                Modify
              </DesignedButton>
              <DesignedButton size="small" onClick={() => handleDeleteClick(row.title)}>
                Delete
              </DesignedButton>
            </StyledCardActions>
          </StyledCard>
        ))}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Typography variant="h6">EDIT POST</Typography>
          <FormContainer>
            <StyledTextField label='Title' variant='filled' required name='title' value={editEntry.title} onChange={handleEditChange} />
            <QuillWrapper>
              <ReactQuill value={editEntry.content} onChange={handleContentChange} />
            </QuillWrapper>
            <StyledButton variant='contained' onClick={saveChanges}>Save</StyledButton>
            {SaveError && <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>{SaveError}</Typography>}
            {SaveSuccess && <Typography color="success" variant="body2" style={{ marginTop: '10px' }}>{SaveSuccess}</Typography>}
          </FormContainer>
        </ModalBox>
      </Modal>

      <Dialog
        open={dialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Post"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
