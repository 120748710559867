import axios from 'axios';

import { API_NOTIFICATION_MESSAGES, SERVICE_URLS } from '../constants/config.js';
const API_URL = 'https://server.uniteenergycorp.com';

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    headers: {
        "content-Type": "application/json"
    }
})

axiosInstance.interceptors.request.use(

    function (config){
        return config;
    },
    function (error){
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.response.use(

    function (response) {
        //stop global loader here
        return processResponse(response);
    },
    function (error){
         //stop global loader here
         return Promise.reject(processError(error));
    }
)

const processResponse = (response) => {
    if(response?.status === 200) {
        return { isSucess : true , data : response.data }
    }else{
        return {
            isSuccess: false,
            status: response?.status,
            msg: response.data?.msg || 'Error fetching data',
            code: response?.code
        };
    }
} 

const processError = (error) => {
    if(error.response){
        console.log('error in response ',error.toJSON)
        return{
            isError:true,
            msg: API_NOTIFICATION_MESSAGES.responseFailure,
            code: error.response.status
        }
    }else if(error.request){
        console.log('error in request ',error.toJSON)
        return{
            isError:true,
            msg: API_NOTIFICATION_MESSAGES.requestFailure,
            code: ""
        }
    }else{
        console.log('error in netwok ',error.toJSON)
        return{
            isError:true,
            msg: API_NOTIFICATION_MESSAGES.networkError,
            code: ""
        }
    }
}

const API = {};

for (const [key, value] of Object.entries(SERVICE_URLS)) {
    API[key] = (body, showUploadProgress, showDownloadProgress) =>
        axiosInstance({
            method: value.method,
            url: value.url,
            data: body,
            responseType: value.responseType,
            onUploadProgress: function(progressEvent) {
                if (showUploadProgress) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    showUploadProgress(percentCompleted);
                }
            },
            onDownloadProgress: function(progressEvent) {
                if (showDownloadProgress) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    showDownloadProgress(percentCompleted);
                }
            }
        });
}

export { API };