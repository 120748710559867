import {
  AppBar,
  Toolbar,
  styled,
  Typography,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Link } from "react-router-dom";
import CallIcon from '@mui/icons-material/Call';
import * as React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const StyledTypography = styled(Typography)`
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledPhTypography = styled(Typography)`
  color: white;
  font-size: 16.25px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledTbTypography = styled(Typography)`
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  margin-right:195px;
`;


const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'white',
  fontSize: '16px',
  fontWeight: '500',
  margin: '0 10px',
  '&:hover': {
    color: '#ccc',
    textDecoration: 'underline'
  },
});

const StyledIconLink = styled('a')({
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  margin: '0 10px',
  '&:hover': {
    color: '#ccc',
  },
});

const Header = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [TabOpen, setTabOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handlToggle = () => {
    setTabOpen((prevState) => !prevState);
  };
  const StyledAppBar = styled(AppBar)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#1976d2',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    zIndex: theme.zIndex.drawer + 1,
  }));

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  }));

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        UECL
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/">
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/about-us">
            <ListItemText primary="About Us" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/abrasive-wheel">
            <ListItemText primary="Abrasive Wheel" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/spares-services">
            <ListItemText primary="Spare and Services" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/boilers">
            <ListItemText primary="Boilers" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/resources">
            <ListItemText primary="Resources" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/blogs">
            <ListItemText primary="Blogs" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
        <StyledIconLink href="tel:+919555082622">
          <CallIcon sx={{ color: 'black' }} />
        </StyledIconLink>
        <StyledIconLink href="mailto:uniteenergycorp@gmail.com">
          <EmailIcon sx={{ color: 'black' }} />
        </StyledIconLink>
      </Box>
    </Box>
  );

  const drawerTab = (
    <Box onClick={handlToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        UECL
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/">
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/about-us">
            <ListItemText primary="About Us" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/abrasive-wheel">
            <ListItemText primary="Abrasive Wheel" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/spares-services">
            <ListItemText primary="Spare and Services" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/boilers">
            <ListItemText primary="Boilers" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/resources">
            <ListItemText primary="Resources" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/blogs">
            <ListItemText primary="Blogs" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );


  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <StyledPhTypography variant="h6">Unite Energy Corporation LLP</StyledPhTypography>
            </>
          ) : isTablet ? (
              <>
                <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handlToggle}
                >
                <MenuIcon />
                </IconButton>
                <StyledTbTypography variant="h6">Unite Energy Corporation LLP</StyledTbTypography>
                <StyledIconLink href="tel:+919555082622"><CallIcon /></StyledIconLink>
                <StyledIconLink href="mailto:uniteenergycorp@gmail.com"><EmailIcon /></StyledIconLink>
              </>
            ):(
            <>
              <StyledTypography variant="h6">Unite Energy Corporation LLP</StyledTypography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <StyledLink to='/'>Home</StyledLink>
                <StyledLink to='/about-us'>About Us</StyledLink>
                <StyledLink to='/abrasive-wheel'>Abrasive Wheel</StyledLink>
                <StyledLink to='/spares-services'>Spare and Services</StyledLink>
                <StyledLink to='/boilers'>Boilers</StyledLink>
                <StyledLink to='/resources'>Resources</StyledLink>
                <StyledLink to='/blogs'>Blogs</StyledLink>
                <StyledIconLink href="tel:+919555082622"><CallIcon /></StyledIconLink>
                <StyledIconLink href="mailto:uniteenergycorp@gmail.com"><EmailIcon /></StyledIconLink>
              </Box>
            </>
          )}
        </StyledToolbar>
      </StyledAppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          anchor="left"
        >
          {drawer}
        </Drawer>
      </nav>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={TabOpen}
          onClose={handlToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md:'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          anchor="left"
        >
          {drawerTab}
        </Drawer>
      </nav>
    </>
  );
}

export default Header;
