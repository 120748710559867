import { Box, Button, TableCell, TableHead, TableRow, TextField, Typography, styled, Paper, TableContainer, Table , TableBody , TablePagination , FormControlLabel , Switch , Modal,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { API } from '../service/api';
import { useState , useEffect } from 'react';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

function EnhancedTableHead() {
    return(
        <TableHead>
            <TableRow>
                <DesignedTableCellS>Name</DesignedTableCellS>
                <DesignedTableCellS>Username</DesignedTableCellS>
                <DesignedTableCellS>Modify</DesignedTableCellS>
                <DesignedTableCellS>Delete</DesignedTableCellS>
            </TableRow>
        </TableHead>
    );
}

const DesignedTableCellS = styled(TableCell)`
    font-size:18px;
    font-weight:semi-bold;
    text-align:justify;
    margin-left:100px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const DesignedTableCell = styled(TableCell)`
    font-size:15px;
    text-align:justify;
    margin-left:100px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const Label = styled(Typography)`
    display: flex;
    text-align: justify;
    font-size: 25px;
    font-weight: bold;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    margin: 8px;
    padding: 8px;
`;

const StyledTextField = styled(TextField)`
    margin: 8px 8px 8px 8px;
    width: 400px;
    font-size: 18px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const StyledButton = styled(Button)`
    margin: 8px;
    padding: 8px;
    width: 100px;
    font-size: 18px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: row;
`;

const ModalBox = styled(Box)`
    position: 'absolute';
    transform: 'translate(-50%, -50%)';
    width: 500px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin:200px 200px 200px 500px;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const userInitialValues = {
    name: '',
    username: '',
    password: ''
}

const AddUser = () => {
    const [error, setError] = useState('');
    const [entry, setEntry] = useState(userInitialValues);
    const [success, setSuccess] = useState('');
    const [page , setPage] = useState(0);
    const [dense , setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [editEntry, setEditEntry] = useState({ username: '', name: '' });
    const [Savesuccess, setSaveSuccess] = useState('');
    const [Saveerror, setSaveError] = useState('');
    const [currentUsername, setCurrentUsername] = useState('');
    const [deleteUser, setDeleteUser] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);


    useEffect(() => {
        axios.get('https://server.uniteenergycorp.com/user/userfetch')
          .then(response => {
            setRows(response.data.reverse());  // Reverse the order of rows
          })
          .catch(error => {
            console.error('There was an error fetching the data!', error);
          });
      }, []);

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
      const handleChangeDense = (event) => {
        setDense(event.target.checked);
      };
    
      const visibleRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

      const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
      const onValueChange = (e) => {
        setEntry({ ...entry, [e.target.name]: e.target.value });
    }

    const userAdd = async () => {
        try {
            let response = await API.addUser(entry);
            if (response.isSucess) { 
                setError('');
                setSuccess('User Added Successfully');
                setEntry(userInitialValues);
                const newUser = {
                    name: entry.name,
                    username: entry.username,
                };
                setRows(prevRows => [newUser, ...prevRows]);
            } else {
                setError('Something went wrong while adding user. Please try again.');
                setSuccess('');
            }
        } catch (error) {
            setError('Something went wrong while adding user. Please try again.');
            setSuccess('');
        }
    }

    const handleOpen = (row) => {
        setEditEntry({ username: row.username, name: row.name });
        setCurrentUsername(row.username);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditChange = (e) => {
        setEditEntry({ ...editEntry, [e.target.name]: e.target.value });
    };

    const saveChanges = async () => {
        try {
            let response = await API.updateUser({
                currentUsername: currentUsername,
                newUsername: editEntry.username,
                newName: editEntry.name
            });

            if (response.data.msg === 'User updated successfully') {
                setSaveError('');
                setSaveSuccess('User Updated Successfully');
                setRows(prevRows => prevRows.map(row => (row.username === currentUsername ? { ...row, username: editEntry.username, name: editEntry.name } : row)));
                handleClose();
            } else {
                setSaveError(response.data.msg || 'Something went wrong while updating user. Please try again.');
                setSaveSuccess('');
            }
        } catch (error) {
            setSaveError('Something went wrong while updating user. Please try again.');
            setSaveSuccess('');
        }
    };
    const handleDeleteClick = (username) => {
        setDeleteUser(username);
        setDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await axios.delete(`https://server.uniteenergycorp.com/user/deleteUser/${deleteUser}`);
            if (response.data.msg === 'User deleted successfully') {
                setRows(prevRows => prevRows.filter(row => row.username !== deleteUser));
                setDeleteUser(null);
                setDialogOpen(false);
            } else {
                console.error(response.data.msg || 'Something went wrong while deleting user.');
            }
        } catch (error) {
            console.error('Error while deleting user:', error);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteUser(null);
        setDialogOpen(false);
    };

    return (
        <Box>
            <Container>
                <Label> ADD USER </Label>
                <Wrapper>
                    <StyledTextField label='Name' variant='filled' required name='name' value={entry.name} onChange={onValueChange} />
                    <StyledTextField label='Username' variant='filled' required name='username' value={entry.username} onChange={onValueChange} />
                    <StyledTextField label='Password' variant='filled' required name='password' type='password' value={entry.password} onChange={onValueChange} />
                    <StyledButton variant='contained' onClick={userAdd}>Add</StyledButton>
                    {error && <Typography color="red" variant="body2" style={{ marginTop: '10px' }}>{error}</Typography>}
                    {success && <Typography color="green" variant="body2" style={{ marginTop: '10px' }}>{success}</Typography>}
                </Wrapper>
            </Container>
            <Box sx={{ width: '100%' }}>
            <div className='mt-[50px]'>
            <Label> USERS </Label> </div>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead />
                    <TableBody>
                    {visibleRows.map((row, index) => {
                        const labelId = `enhanced-table-${index}`;

                        return (
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.name}
                            sx={{ cursor: 'pointer' }}
                        >
                            <DesignedTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            >
                            {row.name}
                            </DesignedTableCell>
                            <DesignedTableCell>{row.username}</DesignedTableCell>
                            <DesignedTableCell><EditIcon style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleOpen(row)} /></DesignedTableCell>
                            <DesignedTableCell><PersonRemoveIcon style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteClick(row.username)} /></DesignedTableCell>
                        </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow
                        style={{
                            height: (dense ? 33 : 53) * emptyRows,
                        }}
                        >
                        <TableCell colSpan={6} />
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
            </Box>            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalBox>
                    <Label> EDIT USER </Label>
                    <StyledTextField label='Name' variant='filled' required name='name' value={editEntry.name} onChange={handleEditChange} />
                    <StyledTextField label='Username' variant='filled' required name='username' value={editEntry.username} onChange={handleEditChange} />
                    <StyledButton variant='contained' onClick={saveChanges}>Save</StyledButton>
                    {Saveerror && <Typography color="red" variant="body2" style={{ marginTop: '10px' }}>{Saveerror}</Typography>}
                    {Savesuccess && <Typography color="green" variant="body2" style={{ marginTop: '10px' }}>{Savesuccess}</Typography>}
                </ModalBox>
            </Modal>
            <Dialog
                open={dialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AddUser;
