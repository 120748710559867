import { Box, Typography, styled , Grid} from '@mui/material';
import { Helmet } from 'react-helmet';
//components
import Newsletter from '../newsletter/newsletter';
import Commitments from '../Home/commitments';

const Content = styled(Box)`
    margin-top: 25px;
`;

const ImageContainer = styled(Box)`
    padding-top: 64.7px;
`;

const Image = styled(Box)`
    background: url('/img/slideshow/Main photo6.jpg') center/55% repeat-x #000;
    background-size: cover;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


const SubHeading = styled(Typography)`
    font-size: 50px;
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 50px;
    font-weight:500;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const Resources = () => {
    return (
        <Box>
            <Helmet>
                <title>Resources - Unite Energy Corporation LLP</title>
            </Helmet>
            <ImageContainer>
                <Image>
                    <SubHeading>RESOURCES</SubHeading>
                </Image>
            </ImageContainer>
            <Content>
                <Grid container spacing={2}>
                    <Grid items xs={12} md={9}>
                        <div>
                        <ul className="ml-[100px] list-disc text-[15.5px] font-serif font-medium mt-[15px] text-justify mr-[40px] tracking-widest">
                                <li>MECHANICAL TEAM</li>
                                <li>INSTRUMENT & ELECTRICAL TEAM</li>
                                <li>EXPERTS TEAM</li>
                                <li>NON-IBR & IBR TEAM</li>
                                <li>OPERATION TEAM</li>
                                <li>ONLINE SUPPORT TEAM</li>
                                <li>EXPERTISE IN ABRASIVES PRODUCTS</li>
                                <li>WE READY 24X7 TO SUPPORT- SPARES & SERVICES</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid items xs={12} md={3}>
                        <Commitments />
                        <Newsletter />
                    </Grid>
                </Grid>
            </Content>
        </Box>

    );
}

export default Resources;
