export const API_NOTIFICATION_MESSAGES = {
    loading: {
        title: "Loading...",
        message: "Data is being loaded. Please wait"
    },
    success: {
        title: "Success",
        message: "Data successfully loaded"
    },
    responseFailure: {
        title: "Error!",
        message: "An error occur while fetching response from server. Please try again"
    },
    requestFailure: {
        title: "Error!",
        message: "An error occur while parsing request data"
    },
    networkError: {
        title: "Error!",
        message: "Unable to connect to the server. Please check internet connectivity and try again."
    }
}

export const SERVICE_URLS = {
    userLogin: { url: '/login', method: 'POST' },
    newsletterEntry: { url:'/newsletter', method: 'POST'},
    contactusEntry : { url:'/contact-us', method: 'POST' },
    addUser: { url: '/user/userSignup', method: 'POST' },
    uploadFile: {url: 'user/file/upload', method:'POST'},
    updateUser: {url: '/user/updateUser', method:'PUT'},
    updatePost: {url:'/user/updatePost', method:'PUT'}
}