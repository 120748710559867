import * as React from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Box, styled } from '@mui/material';

const DesignedButton = styled(Button)`
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  padding:10px;
  margin:10px;
  font-size:18px;
`;

const Wrapper = styled(Box)`
  padding-top:100px;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:40px;
`;

const Heading = styled(Typography)`
  margin:15px;
  text-align:center;
  font-size:25px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight:1000;
  color:#5757bd;
`;

const Para = styled(Typography)`
  font-size:20px;
  color:#000000;
  padding:8px;
  text-align:justify;
  font-weight: 400;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;

  // Add margin to paragraphs
  p {
    margin-bottom: 20px; // Adjust the value as needed
  }
`;

export default function BlogDetail() {
  const { id } = useParams();
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(`https://server.uniteenergycorp.com/user/post/${id}`)
      .then(response => {
        setPost(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the post!', error);
      });
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      {post.map((row) => (
        <Box key={row.id}>
          <Heading>{row.title}</Heading>
          <Para dangerouslySetInnerHTML={{ __html: row.content }} className="content"></Para>
          {row.pdf_file_path && (
            <DesignedButton
              size="small"
              href={`https://server.uniteenergycorp.com/${row.pdf_file_path.replace(/\\/g, '/')}`} // Fix path format
              target="_blank"
              rel="noopener noreferrer"
              variant='contained'
            >
              Attachment
            </DesignedButton>
          )}
        </Box>
      ))}
    </Wrapper>
  );
}
