import React, { useState } from 'react';
import { Button, TextField, Typography, Grid, Paper } from '@mui/material';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreatePost = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!title) newErrors.title = 'Title is required';
        if (!content) newErrors.content = 'Content is required';
        if (!file) newErrors.file = 'A file is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile ? selectedFile.name : '');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;
    
        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('file', file);

        try {
            await axios.post('https://server.uniteenergycorp.com/user/file/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Post created successfully!');
            setTitle('');
            setContent('');
            setFile(null);
            setFileName('');
            setErrors({});
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file');
        }
    };

    return (
        <Paper style={{ padding: '20px' }}>
            <Typography variant="h5" gutterBottom>
                Create Post
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="title"
                            label="Title"
                            variant="outlined"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            error={Boolean(errors.title)}
                            helperText={errors.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReactQuill
                            value={content}
                            onChange={setContent}
                            placeholder="Write your content here..."
                        />
                        {errors.content && (
                            <Typography variant="body2" color="error">
                                {errors.content}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <input
                            accept="application/pdf"
                            id="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="file-upload">
                            <Button variant="contained" component="span">
                                Upload PDF
                            </Button>
                        </label>
                        {fileName && (
                            <Typography variant="body2" color="textSecondary">
                                {fileName}
                            </Typography>
                        )}
                        {errors.file && (
                            <Typography variant="body2" color="error">
                                {errors.file}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Publish
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default CreatePost;
