import { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, CardActionArea, styled, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const CardItems = [
  { image: '/img/carousel/design.jpg', title: 'Design & Modification', description: 'We handle all your needs from Design to Modification in Power Plant.' },
  { image: '/img/carousel/performance.jpg', title: 'Performance & Updation', description: 'We evaluate and improve the plants Performance & make it Energy Saving.' },
  { image: '/img/carousel/repair & ret1.jpg', title: 'Repair & Retrofit & Erection', description: 'We provide best team to any type of Repair & Retrofit & Erection in Thermal Power Plant.' },
  { image: '/img/carousel/sales.jpg', title: 'Sales & Services & Spares', description: 'We are ready to handle all your experts requirements for any checks and control.' },
  { image: '/img/carousel/servive consultancy.jpg', title: 'Consultancy & Technical Support', description: 'We provide our best ideas and Resolve Technical Queries to correct before failure..' }
];

const Wrapper = styled(Box)(({ theme }) => ({
  margin: 5,
  padding: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 100,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  [theme.breakpoints.down('md')]: {
    gap:5
  },
}));

const CardHeading = styled(Typography)`
  font-size: 20px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  margin: 5px;
  padding: 5px;
  font-weight: 700;
`;

const Content = styled(Typography)`
  font-size: 15px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  margin: 5px;
  padding: 5px;
  font-weight: 500;
  text-align: justify;
`;

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  [theme.breakpoints.down('sm')]: {
    top: 'auto',
    transform: 'translateY(0)',
    '&:first-of-type': {
      left: '20px',
    },
    '&:last-of-type': {
      right: '20px',
    },
  },
  [theme.breakpoints.down('md')]: {
    top: 'auto',
    transform: 'translateY(0)',
    '&:first-of-type': {
      left: '20px',
    },
    '&:last-of-type': {
      right: '20px',
    },
  },
}));

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    let interval;
    if (!hovered) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % CardItems.length);
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [hovered]);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const handlePrevClick = () => setCurrentIndex((currentIndex - 1 + CardItems.length) % CardItems.length);
  const handleNextClick = () => setCurrentIndex((currentIndex + 1) % CardItems.length);

  const getVisibleIndices = () => {
    const prevIndex = (currentIndex - 1 + CardItems.length) % CardItems.length;
    const nextIndex = (currentIndex + 1) % CardItems.length;
    if(window.innerWidth <= 600)
      return  [currentIndex] ;
    else if(window.innerWidth >600 && window.innerWidth<=1200)
      return [prevIndex,currentIndex];
    else
      return [prevIndex, currentIndex, nextIndex];
  };

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ArrowButton style={{ left: 0 }} onClick={handlePrevClick}>
        <ArrowBackIos />
      </ArrowButton>
      {getVisibleIndices().map((index) => (
        <Card
          key={index}
          sx={{
            maxWidth: 345,
            height: 400,
            transition: 'transform 0.5s ease-in-out',
            backgroundColor: '#E0FFFF',
            margin: '0 10px',
            '@media (max-width: 600px)': {
              maxWidth: '80%',
              margin: '10px 0',
            },
          }}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="200"
              image={CardItems[index].image}
              alt={CardItems[index].title}
              sx={{ objectFit: 'cover' }}  // Ensures image fits well within the fixed height
            />
            <CardContent sx={{ height: 200, overflowY: 'auto' }}>
              <CardHeading gutterBottom variant="h5" component="div">
                {CardItems[index].title}
              </CardHeading>
              <Content variant="body2" color="text.secondary">
                {CardItems[index].description}
              </Content>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
      <ArrowButton style={{ right: 0 }} onClick={handleNextClick}>
        <ArrowForwardIos />
      </ArrowButton>
    </Wrapper>
  );
};

export default Carousel;
