import {
    Box,
    Typography,
    TextField,
    Button,
    styled,
    TextareaAutosize,
  } from "@mui/material";
  import { API } from "../service/api";
  import { useState } from "react";
  
  const Wrapper = styled(Box)(({ theme }) => ({
    marginTop: 30,
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  }));
  
  const Pairs = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      width: '100%',
    },
  }));
  
  const Label = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    fontSize: 40,
    fontWeight: 'bold',
    textUnderlineOffset: 2,
    fontFamily: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  }));
  
  const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: '8px 8px 8px 8px',
    width: 600,
    fontSize: 18,
    fontFamily: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  }));
  
  const TextArea = styled(TextareaAutosize)(({ theme }) => ({
    marginTop: '8px',
    width: 1216,
    fontSize: 18,
    backgroundColor: '#f5f5f5',
    border: '1px solid #e0e0e0',
    borderRadius: 4,
    transition: 'border-color 0.3s ease',
    fontFamily: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      marginLeft:'0px',
      padding:0,
    },
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: 8,
    width: 200,
    fontSize: 18,
    fontFamily: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  }));
  
  const formInitialValues = {
    firstname: '',
    lastname: '',
    email: '',
    number: '',
    description: ''
  }
  
  const Contact = () => {
  
    const [error, setError] = useState('');
    const [entry, setEntry] = useState(formInitialValues);
    const [success, setSuccess] = useState('');
  
    const onValueChange = (e) => {
      setEntry({ ...entry, [e.target.name]: e.target.value });
    }
  
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    }
  
    const contactusForm = async () => {
      if (!validateEmail(entry.email)) {
        setError('Please enter a valid email address.');
        setSuccess('');
        return;
      }
  
      try {
        let response = await API.contactusEntry(entry);
        if (response.isSucess) { // Adjusted to check for isSucess
          setError('');
          setSuccess('We have received your message and will get back to you shortly.');
          setEntry(formInitialValues); // Clear the email field on success
        } else {
          setError('Something went wrong. Please try again.');
          setSuccess('');
        }
      } catch (error) {
        setError('Something went wrong. Please try again.');
        setSuccess('');
      }
    }
  
    return (
      <Wrapper>
        <Label>Contact Us</Label>
        <Pairs>
          <StyledTextField
            label="First Name"
            variant="filled"
            name="firstname"
            required
            value={entry.firstname}
            onChange={(e) => onValueChange(e)}
          />
          <StyledTextField
            label="Last Name"
            variant="filled"
            name="lastname"
            value={entry.lastname}
            onChange={(e) => onValueChange(e)}
          />
        </Pairs>
        <Pairs>
          <StyledTextField
            label="Email"
            variant="filled"
            name="email"
            required
            value={entry.email}
            onChange={(e) => onValueChange(e)}
          />
          <StyledTextField
            label="Mobile No."
            variant="filled"
            name="number"
            type="number"
            value={entry.number}
            onChange={(e) => onValueChange(e)}
          />
        </Pairs>
        <TextArea
          placeholder="Your Message..."
          minRows={3}
          maxRows={3}
          name="description"
          required
          value={entry.description}
          onChange={(e) => onValueChange(e)}
        />
        <StyledButton variant="contained" onClick={contactusForm}>Submit</StyledButton>
        {success && <Typography color="green" variant="body2" style={{ marginTop: '10px' }}>{success}</Typography>}
        {error && <Typography color="red" variant="body2" style={{ marginTop: '10px' }}>{error}</Typography>}
      </Wrapper>
    );
  }
  
  export default Contact;
  